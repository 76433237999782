



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({ components: {} })
export default class ThirdPartyAdminActionsModal extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public modalContent!: string;
  @Prop()
  public confirmText!: string;

  public confirmAction() {
    this.$emit('confirm');
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('close');
  }
}
