


























































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { RootState } from '@/store/store';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import {
  ThirdPartyUser,
  ThirdPartyUserActions
} from '../types/admin-third-party-management.types';
import ThirdPartyAdminActionsModal from './ThirdPartyAdminActionsModal.vue';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class ThirdPartyUsersList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Third Party Name', sortColumn: 'username', sort: true },
    { placeholder: 'Group Type', sortColumn: 'groupType', sort: false },
    { placeholder: 'Status', sort: false }
  ];
  @Prop() public items!: ThirdPartyUser[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @State(({ admin }: RootState) => admin.apiState.getThirdPartyUsers.success)
  public getThirdPartyUsersSuccess!: boolean;

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  public toggleStatus(item: ThirdPartyUser) {
    const confirmText = item.status === 'Active' ? 'Deactivate' : 'Activate';
    const modalTitle = confirmText + ' Access for ' + item.username;
    const modalContent =
      item.status === 'Active'
        ? 'This user will be rendered inactive and the third party will no longer be able to access the system.'
        : 'This user will become active and the third party will be able to access the system.';
    this.$buefy.modal.open({
      parent: this,
      component: ThirdPartyAdminActionsModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        modalContent,
        confirmText
      },
      events: {
        confirm: () => {
          if (item.status === 'Active') {
            this.$emit('updateThirdPartyUser', {
              id: item.id,
              action: ThirdPartyUserActions.DEACTIVATE
            });
          } else {
            this.$emit('updateThirdPartyUser', {
              id: item.id,
              action: ThirdPartyUserActions.ACTIVATE
            });
          }
        }
      }
    });
  }

  public regeneratePassword(item: ThirdPartyUser) {
    const confirmText = 'Generate';
    const modalTitle = 'Regenerate New Password?';
    const modalContent = `Are you sure you want to regenerate new password for ${item.username}?`;
    this.$buefy.modal.open({
      parent: this,
      component: ThirdPartyAdminActionsModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        modalContent,
        confirmText
      },
      events: {
        confirm: () => {
          this.$emit('updateThirdPartyUser', {
            id: item.id,
            action: ThirdPartyUserActions.REGENERATE
          });
        }
      }
    });
  }

  public deleteThirdPartyUser(item: ThirdPartyUser) {
    const confirmText = 'Delete';
    const modalTitle = 'Delete Access for ' + item.username;
    const modalContent =
      'The third party user will no longer be able to access the system. This action cannot be undone.';
    this.$buefy.modal.open({
      parent: this,
      component: ThirdPartyAdminActionsModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        modalContent,
        confirmText
      },
      events: {
        confirm: () => {
          this.$emit('updateThirdPartyUser', {
            id: item.id,
            action: ThirdPartyUserActions.DELETE
          });
        }
      }
    });
  }
}
