































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GroupTypesDropdown } from '../../../store/modules/admin/types/group-type.types';
import { FilteredThirdPartyUserPayload } from '../types/admin-third-party-management.types';

@Component({
  components: {}
})
export default class SearchBox extends Vue {
  @Prop(Object) public filterParams!: FilteredThirdPartyUserPayload;
  @Prop()
  public groupTypes!: GroupTypesDropdown;

  public allGroupTypeValue: GroupTypesDropdown = {
    id: 0,
    name: 'All Group Types'
  };

  @Watch('filterParams', { deep: true })
  public onChange() {
    this.$emit('input', this.filterParams);
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
