import { Pagination } from 'nestjs-typeorm-paginate';
import { DateOrString } from '../../../jbi-shared/types/full-document-revision-object.types';
import { MyjbiGroupType } from '../../../jbi-shared/types/myjbi-group.types';
import { GroupTypesDropdown } from '../../../store/modules/admin/types/group-type.types';

export interface ThirdPartyUser {
  id: number;
  username: string;
  passwordHash?: string;
  groupType: MyjbiGroupType;
  createdDate: DateOrString;
  updatedDate: DateOrString;
  deletedDate: DateOrString;
  status: ThirdPartyUserStatus;
}

export enum ThirdPartyUserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted'
}

export enum ThirdPartyUserActions {
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  DELETE = 'Delete',
  REGENERATE = 'Regenerate'
}

export type GetThirdPartyUsersResponsePayload = Pagination<ThirdPartyUser>;

export interface GetThirdPartyUsersRequestPayload {
  username?: string;
  groupType?: GroupTypesDropdown;
  status?: string;
  page: number;
  limit: number;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
}

export interface CreateThirdPartyUserPayload {
  username: string;
  groupTypeId: number;
}

export type FilteredThirdPartyUserPayload = Omit<
  GetThirdPartyUsersRequestPayload,
  'page' | 'limit' | 'sortOrder' | 'sortColumn'
>;

export interface UpdateThirdPartyUserPayload {
  id: number;
  action: ThirdPartyUserActions;
}

export interface ThirdPartyUserCredentials {
  username: string;
  secret: string;
}
