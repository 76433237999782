














import { Component, Vue, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import ThirdPartyAccessManagementTab from './ThirdPartyAccessManagement.tab.vue';

const tabs = [
  {
    tab: 'Basic Authentication',
    groupname: '',
    limit: '50',
    page: '1',
    sortColumn: 'username',
    sortOrder: 'ASC'
  }
];

@Component({
  components: {
    Container,
    ThirdPartyAccessManagementTab
  }
})
export default class AdminThirdPartyManagement extends Vue {
  public activeTab = 0;
  public disableTab = true;

  public mounted() {
    if (this.$route.query.tab) {
      const index = tabs.findIndex((res) => res.tab === this.$route.query.tab);
      if (index >= 0) {
        this.activeTab = +index;
        this.disableTab = false;
      }
    }
  }

  public activateTab() {
    this.disableTab = false;
  }

  @Watch('activeTab')
  public onTabChange(value: number) {
    this.disableTab = true;

    if (this.$route.query.tab === tabs[value].tab) {
      return;
    }

    this.$router.replace({ query: undefined });

    this.$router.push({
      query: {
        ...(tabs as { [key: string]: any })[value]
      }
    });
  }
}
