























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { GroupType } from '../../../store/modules/admin/types/admin.types';

@Component({ components: {} })
export default class CreateThirdPartyUserModal extends Vue {
  @Prop()
  public groupTypes!: GroupType[];

  public partyName: string = '';
  public tempGroupType: GroupType | null = null;
  public isDisabled: boolean = true;

  public confirm() {
    this.$emit('generate', {
      partyName: this.partyName,
      groupType: this.tempGroupType
    });
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('close');
  }

  @Watch('partyName')
  public watchPartyName() {
    if (this.partyName && this.tempGroupType) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  @Watch('tempGroupType')
  public watchTempGroupType() {
    if (this.partyName && this.tempGroupType) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
}
