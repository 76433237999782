






































import { Component, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { ThirdPartyUserExportToCsv } from '@/components/export/csv/third-party-user/ExportCsv';
import { Action, State } from 'vuex-class';
import { RootState } from '../../store/store';
import CreateThirdPartyUserModal from '@/views/AdminThirdPartyManagement/components/CreateThirdPartyUserModal.vue';
import SearchBox from './components/SearchBox.vue';
import ThirdPartyUsersList from './components/ThirdPartyUsersList.vue';
import { get as _get } from 'lodash';
import { ApiState } from '@/store/types/general.types';
import {
  CreateThirdPartyUserPayload,
  FilteredThirdPartyUserPayload,
  GetThirdPartyUsersRequestPayload,
  GetThirdPartyUsersResponsePayload,
  ThirdPartyUserActions,
  ThirdPartyUserCredentials,
  UpdateThirdPartyUserPayload
} from './types/admin-third-party-management.types';
import { GroupType } from '../../store/modules/admin/types/admin.types';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import { GroupTypesDropdown } from '../../store/modules/admin/types/group-type.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { adminApi } from '../../api/admin.api';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

const initialState = {
  username: '',
  groupType: {
    id: 0,
    name: 'All Group Types'
  },
  status: ''
};

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BasePaginatorHoc,
    CreateThirdPartyUserModal,
    SearchBox,
    ThirdPartyUsersList
  }
})
export default class ThirdPartyAccessManagementTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'username';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredThirdPartyUserParams: FilteredThirdPartyUserPayload = {
    ...initialState
  };
  public tempGroupTypes: GroupTypesDropdown[] = [];
  public thirdPartyUsersListKey: number = Math.floor(Math.random() * 999);

  @Action('admin/getThirdPartyUsers')
  getThirdPartyUsers!: (
    options: Partial<GetThirdPartyUsersRequestPayload>
  ) => void;

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupTypes)
  public getGroupTypesStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  @Action('admin/createThirdPartyUser')
  createThirdPartyUser!: (payload: CreateThirdPartyUserPayload) => void;

  @Action('admin/updateThirdPartyUser')
  updateThirdPartyUser!: (payload: UpdateThirdPartyUserPayload) => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupLicenses)
  public getGroupLicensesStatus!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.createThirdPartyUser)
  public createThirdPartyUserState!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.updateThirdPartyUser)
  public updateThirdPartyUserState!: ApiState;

  @State(({ admin }: RootState) => admin.thirdPartyUsers)
  public thirdPartyUsers!: GetThirdPartyUsersResponsePayload;

  openCreateThirdPartyUserModal() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateThirdPartyUserModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        groupTypes: this.groupTypes
      },
      events: {
        generate: (data: { partyName: string; groupType: GroupType }) => {
          const payload: CreateThirdPartyUserPayload = {
            username: data.partyName,
            groupTypeId: data.groupType.id
          };
          this.createThirdPartyUser(payload);
        }
      }
    });
  }

  async handleResetQuery() {
    return this.getThirdPartyUsers(initialState);
  }

  get ThirdPartyUsersList() {
    return ThirdPartyUsersList;
  }

  public async handleFilter() {
    this.getThirdPartyUsers(this.filteredThirdPartyUserParams);
  }

  public handleResetFilter() {
    this.filteredThirdPartyUserParams = { ...initialState };
  }

  public mounted() {
    this.getThirdPartyUsers(this.filteredThirdPartyUserParams);
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-group_types-create_group_types'
      )
    ) {
      this.getGroupTypes();
    }
  }

  public handleUpdate(payload: UpdateThirdPartyUserPayload) {
    if (payload.action === ThirdPartyUserActions.REGENERATE) {
      adminApi.updateThirdPartyUser(payload).then((response: any) => {
        this.handleExportToCsv(response.data);
      });
    } else {
      this.updateThirdPartyUser(payload);
    }
  }

  public handleExportToCsv(data: ThirdPartyUserCredentials) {
    const exportToCsv = new ThirdPartyUserExportToCsv();
    exportToCsv.create(data);
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      ...this.filteredThirdPartyUserParams,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };
    this.getThirdPartyUsers(params);
  }

  @Watch('getGroupTypesStatus.success')
  @isTruthy
  public getGroupTypesStatusSuccess() {
    this.tempGroupTypes = this.groupTypes.map((groupType: GroupType) => {
      return {
        id: groupType.id,
        name: groupType.name
      };
    });
  }

  @Watch('createThirdPartyUserState.success')
  @isTruthy
  public createThirdPartyUserStateSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: 'Third party access created'
    });
    this.getThirdPartyUsers(this.filteredThirdPartyUserParams);
  }

  @Watch('updateThirdPartyUserState.success')
  @isTruthy
  public updateThirdPartyUserStateSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: 'Third party access updated'
    });
    this.getThirdPartyUsers(this.filteredThirdPartyUserParams);
  }

  @Watch('createThirdPartyUserState.error')
  @isTruthy
  public createThirdPartyUserStateError() {
    Toast.open({
      message: _get(
        this.createThirdPartyUserState.error,
        'response.data.message',
        'Unknown Error'
      ),
      position: 'is-top',
      type: 'is-danger'
    });
  }

  @Watch('updateThirdPartyUserState.error')
  @isTruthy
  public updateThirdPartyUserStateError() {
    Toast.open({
      message: _get(
        this.updateThirdPartyUserState.error,
        'response.data.message',
        'Unknown Error'
      ),
      position: 'is-top',
      type: 'is-danger'
    });
  }
}
